import Main from "./Main";
import "./styles.css";

export default function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <Main />
    </div>
  );
}
